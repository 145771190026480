import React from 'react'
import { Modal, Button } from 'antd'
import Layout from '../../components/LayoutSimple'

export default class ProjectIndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myListItems: [],
      isValidated: false,
      isLoading: false,
      showModal: false,
    }
  }

  render() {
    return (
      <Layout>
        <section>
          Missing
        </section>        
      </Layout>
    )
  }
}
